<template>
  <div class="privacy-agreement">
    <h2>用户协议</h2>
    <div v-html="detail" class="details"></div>
  </div>
</template>

<script>
import { getUserPrivacyProtocol } from '@/api/api'
export default {
  name: "PrivacyAgreement",
  components: {
  },
  data() {
    return {
      detail: '', //内容
      keyword: '隐私政策', //查询关键词
    };
  },
  created() {
    this.getDetails();
  },
  mounted() {
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  },
  methods: {
    getDetails() {
      getUserPrivacyProtocol().then((res) => {
        if (res.result === 0) {
          this.detail = res.data.articleBody
        } else {
        }
      }).catch((err) => {
      })
    }
  }
};
</script>

<style lang="less" scoped>
.privacy-agreement {
  padding: 0 10px;
  h2 {
    padding-top: 30px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
  .details{
    padding: 10px 12px;
    line-height: 25px;
    font-size: 13px;
  }
  :deep( img){
    max-width: 100%;
  }
  :deep( a){
    word-wrap: break-word;
  }
  :deep( video){
    max-width: 100%;
  }
  :deep( p){
    word-break: break-all;
  }
  :deep( p,div){
    margin-top: 10px;
  }
}

</style>
